
export default function FeedBackPage(){

    return(
        <div>
            <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeGzKhmFWtnzPNVxTkl8KVvVJpMPF5-__O8rIV6WSva2N1HQw/viewform?embedded=true" width="640" height="2214" >Loading…</iframe>
            </div>
        </div>
    )
}