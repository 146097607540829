import { Card } from "react-bootstrap";
import {useState} from 'react';
import Button from '@mui/material/Button';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LanguageIcon from '@mui/icons-material/Language';
import HeaderComponent from "../headers/headerhome";
import LoadingSpinner from "../../animations/Loadingspinner";
import { send } from 'emailjs-com';
import { maxRowBasedquery } from "../mediahooks/mediamax";
import useMediaQuery from "../mediahooks/useMedia";
import Policies from "../homepage/components/policies";
import axios from "axios";
//import './contactus.css';
//https://dev.to/daliboru/how-to-send-emails-from-a-form-in-react-emailjs-27d1
function BootCard(props:any){
	return(
		<Card bg={"dark"} >
			<div style={{margin:"30px"}}><h3 style={props.styles.textcolor}>Get In Touch</h3></div>
			<Card.Body>
				<Card.Title>Hello</Card.Title>
				<Card.Text >
					Hob
				</Card.Text>
			</Card.Body>
			<Card.Footer>
				<p >Check it out &rarr; </p>
			</Card.Footer>
		</Card>
	)
}
export default function ContactUs(){
	const [isLoadingLogin, setIsLoadingLogin] = useState<Boolean>(false); // State dealing with loading spinner
	const [formnotcompleted,setFormNotCompleted] = useState<Boolean>(false);
	const [formcompleted,setFormCompleted] = useState<Boolean>(false);
	const maxRowBased = useMediaQuery(maxRowBasedquery)


	class ContactUsStyles{
		contactustitle:Object;
		contactuscont:Object;
		textcolor:Object;
		contactsub:Object;
		contactsubcont:Object;
		constructor(){
			this.textcolor = {color:"white"}
			this.contactustitle = {display:"flex",justifyContent:"center",position:"relative",top:"40px",marginTop:"20px"}
			this.contactuscont = {display:"flex",justifyContent:"center",position:"relative",top:"70px", gap: maxRowBased ? "350px":"20px",flexDirection: maxRowBased ? "row"  : "column"}
			this.contactsub = {color:"white",marginTop:"5px"}
			this.contactsubcont = {color:"grey",marginRight:"10px",fontSize:"30px"}
		}
	}
	let styles = new ContactUsStyles()

	


	

	/*								
	<div style={{display:"flex"}}>
	<PhoneIcon style={props.styles.contactsubcont}></PhoneIcon>
	<h3 style={props.styles.contactsub}>Phone</h3>
	</div> 
	<div>
	<p style={{position:"relative",left:"40px",color:"white"}}>+44 'Business Number'</p>
	</div>*/
	function ContactDetails(props:any){
		return(
			<div className="contact row">
			<div style={{position:"relative",top:"20px", left:maxRowBased ? "none" : "20px"}}>
				<div style={{display:"flex",flexDirection:"column",gap:"20px"}}>
					<h2 style={{color:"white"}}>Contact Us</h2>
					<div style={{display:"flex"}}>
						<EmailIcon style={props.styles.contactsubcont}></EmailIcon>
						<h3 style={props.styles.contactsub}>Email Address:</h3>
						
					</div>
					<div>
						<p style={{position:"relative",left:"40px",color:"white"}}>amari.lawal@gmail.com</p>
					</div>
					{/*Phone Icon Here*/}

					<div style={{display:"flex"}}>
						<GitHubIcon style={props.styles.contactsubcont}/>
						<h3 style={props.styles.contactsub}>Github:</h3>
					</div>
					<div>
						<a href="https://github.com/Amari-Lawal/" target="_blank" rel="noopener noreferrer" style={{position:"relative",left:"40px",color:"white"}}>https://github.com/Amari-Lawal</a>
					</div>
					<div style={{display:"flex"}}>
						<LanguageIcon style={props.styles.contactsubcont}/>
						<h3 style={props.styles.contactsub}>Website</h3>
					</div>
					<div>
						<a href="https://amari.dev" target="_blank" rel="noopener noreferrer" style={{position:"relative",left:"40px",color:"white"}}>https://amari.dev</a>
					</div>
				</div>
			</div>
			
		
		
		</div>
		)
	}
	function EmailPrompt(props:any){
	  const [name,setName] = useState("");
	  const [message,setMessage] = useState("");
	  const [reply_to,setReplyTo] = useState("");
	  const [loading,setLoading] =useState(false);
	  const onSubmit = async () =>{	
		setLoading(true)
		if (name === "" || message === "" || reply_to === ""){
			alert("Fill in all fields please")
			setLoading(false)
		}
		else{
			const response = await axios.post("https://caesaraicronemail-qqbn26mgpa-uc.a.run.app/sendemail",{"email":"revisionbankedu@gmail.com","message":message,"subject":`RevisionBank Customer Support - ${name} | ${reply_to}`})
			let result = response.data
			if ("message" in result){
				alert(result.message)
				setLoading(false)
			}
			else{
				alert("Please email revisionbankedu@gmail.com directly with your query.")
			}
			
		}
	  }
		return(
			<div style={{height:"60vh",width:maxRowBased ? "1000px" :"400px",backgroundColor:"white",borderRadius:"10px",position: "relative" }}>
				<div style={{display:"flex",flexDirection:"column",gap:"20px",justifyContent:"center",alignItems:"center",marginTop:"40px"}}>
					<h1 style={{color:"black",fontSize:"20px"}}>Contact us</h1>
					<input
						type='text'
						name='Name'
						placeholder='Name'
						value={name}
						onChange={(e) =>{setName(e.target.value)}}
					/>

					<input
						type='text'
						name='reply_to'
						placeholder='Your email'
						value={reply_to}
						onChange={(e) =>{setReplyTo(e.target.value)}}
						style={{border:"1px solid black",borderRadius:"3px"}}
					/>
					<textarea
					style={{width:maxRowBased ? "700px":"300px",height:maxRowBased ? "500px" :"200px",border:"1px solid black",borderRadius:"3px"}}
						name='message'
						placeholder=' Your message'
						defaultValue={message}
						onChange={(e) =>{setMessage(e.target.value)}}
					/>
					
					
					{loading === false ? 
					<Button variant="contained" style={{fontSize:"15px",width:"100px"}} onClick={onSubmit}>Submit</Button> :
					<Button variant="contained" style={{backgroundColor:"grey",fontSize:"15px",width:"100px"}}>Submit</Button>}

				</div>
		</div>
		)
	}
	return(
		<div>
			<div>
			<HeaderComponent/>
			<div style={{display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",gap:"80px"}}>
				<h1 style={{color:"white"}}>Contact Us</h1>
				<EmailPrompt/>
			</div>
			<Policies></Policies>
			</div>
			


		</div>
    )

}

/*										
<input onChange={(e:any) => {setName(e.target.value)}} style={{width:"100%"}} placeholder="Name"></input>
<input onChange={(e:any) => {setEmail(e.target.value)}} style={{width:"100%"}} placeholder="Email"></input>
<input onChange={(e:any) => {setSubject(e.target.value)}} style={{width:"100%"}} placeholder="Subject"></input>
<input onChange={(e:any) => {setMessage(e.target.value)}} style={{height: "100px",padding: "10px",width:"100%"}} placeholder="Message"></input>
<Button onClick={sendEmail} variant="contained"  >Send Message</Button>
										 */